const onOffTreatment = {
  on: 'on',
  off: 'off',
};

const crFeatureKeys = {
  aboutPageUpdates: 'consumer_about_page_frontend',
  homepageCarousel: 'consumer_homepageCarousel_web',
  wallet: {
    pointstoDollars: 'wallet_pointsToDollars_frontend',
    alistProfile: 'wallet2_AListProfile_frontend',
    profileEnhancement: 'wallet1_profileEnhancement_frontend',
    consumerWallet2Frontend: 'consumer_wallet_2_frontend',
    consumerPointsBankingFrontend: 'consumer_points_banking_frontend',
    consumerWalletPointsActivity: 'consumer_wallet_2_points_activity_frontend',
  },
  landingPage: {
    juvDayStandBy: 'consumer_juvDayStandBy_frontend',
    botoxDayStandby: 'consumer_botoxDayStandby_frontend',
    consumerBotox23DayContentfulDownRedirect:
      'consumer_botox23_day_contentful_down_redirect',
  },
} as const;

export const FinancingApplicationFeatureValues = {
  Cherry: 'cherry',
  Off: 'off',
  /**
   * Control value is returned from split
   * when the split is not defined in the split config
   * or there is an connection error to split service
   */
  Control: 'control',
} as const;

export type FinancingApplicationFeature =
  (typeof FinancingApplicationFeatureValues)[keyof typeof FinancingApplicationFeatureValues];

const financingFeatureKeys = {
  application: 'financing_eligibilityCheck_web',
  walletNewUser: 'financing_walletNewUser_web',
  fundsApproval: 'financing_consumerApproval_web',
} as const;

const growFeatureKeys = {
  landingPage: {
    layout: 'growLandingPage_layout_web',
  },
  offerActivation: {
    errorMessage: 'growOfferActivation_errorMessage_web',
  },
  practiceCampaignCard: {
    hideChatNow: 'grow_practiceCampaignCard_hideChatNow',
  },
} as const;

const optimizeRegFeatureKeys = {
  autofocusRegFields: 'consumer_registrationFieldsAutofocus_web',
  optInOfferInlineClaim: 'consumer_optInOfferInlineClaim_web',
} as const;

const optimizeRegTreatments = {
  [optimizeRegFeatureKeys.autofocusRegFields]: onOffTreatment,
  [optimizeRegFeatureKeys.optInOfferInlineClaim]: onOffTreatment,
} as const;

const pdFeatureKeys = {
  providerDirectoryShowFreeConsultation:
    'providerDirectory_showFreeConsultation_web',
  providerDirectoryBrowserLocationPrompt:
    'providerDirectory_browserLocationPrompt_web',
  providerDirectoryBrowserLocationPromptLegacy:
    'providerDirectory_browserLocationPromptLegacy_web',
  providerDirectoryGoogleGeocoding: 'providerDirectory_googleGeocoding_web',
  providerDirectoryReactQuery: 'providerDirectory_reactQuery_web',
  providerDirectoryConsultationOfferVariant:
    'providerDirectory_consultationOfferVariants_web',
  providerDirectoryFindAProviderButton:
    'providerDirectory_findAProviderButton_web',
  providerDirectorySearchResultsRedesign:
    'providerDirectory_searchResultsRedesign_web',
  providerDirectoryProfilePageRedesign:
    'providerDirectory_profilePageRedesign_web',
  providerDirectorySearchSheetRedesign:
    'providerDirectory_searchSheetRedesign_web',
  providerDirectorySearchProviderName:
    'providerDirectory_searchProviderName_web',
  providerDirectorySearchResultsPerPage:
    'providerDirectory_searchResultsPerPage_web',
} as const;

// consumer acquisition squad
const caFeatureKeys = {
  howItWorksRedesign: 'howItWorks_redesign_web',
  imageOptimizations: 'consumer_imageOptimizations_web',
  pocMultiApp: 'providerDirectory_pocMultiApp_web',
  providerAboutUs: 'consumer_providerProfileAboutUs_web',
  permanent: {
    hiddenTreatmentAreas: 'consumer_hiddenTreatmentAreasPermanent_web',
  },
  footerRedesign: 'consumer_footerRedesign_web',
};

const caTreatments = {
  [caFeatureKeys.imageOptimizations]: onOffTreatment,
  [caFeatureKeys.howItWorksRedesign]: onOffTreatment,
  [caFeatureKeys.footerRedesign]: onOffTreatment,
};

const rafFeatureKeys = {
  referralWideScale: 'refer_a_friend_wide_scale',
} as const;

const passTheLeadFeatureKeys = {
  passTheLeadInlineOfferClaim: 'ges1_passTheLeadExperiment_frontend',
  passTheLeadRouteRegistrationFlow: 'ges1_passTheLeadRouteRegistrationFlow_web',
  passTheLeadSettings: 'ges1_passTheLeadSettings_frontend',
  passTheLeadNoButtonSearch: 'ges1_passTheLeadNoButtonSearch_web',
  passTheLeadConfirmationWithContactStep:
    'ges1_passTheLeadConfirmationWithContactStep_web',
};

const passTheLeadRegTreatments = {
  [passTheLeadFeatureKeys.passTheLeadInlineOfferClaim]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadRouteRegistrationFlow]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadSettings]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadNoButtonSearch]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadConfirmationWithContactStep]:
    onOffTreatment,
};

export {
  crFeatureKeys,
  financingFeatureKeys,
  growFeatureKeys,
  optimizeRegFeatureKeys,
  optimizeRegTreatments,
  pdFeatureKeys,
  rafFeatureKeys,
  caFeatureKeys,
  caTreatments,
  passTheLeadFeatureKeys,
  passTheLeadRegTreatments,
};
